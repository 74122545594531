




































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { CustomerDto, CustomerType } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import AbSelect from "@/components/AbSelect.vue";

@Component({
  components: {
    PagedTableView,
    AbSelect,
  },
})
export default class Enterprise extends Vue {
  editId = 0;
  queryForm = {
    customerCode: "",
    realName: "",
    mobilePhone: "",
    email: "",
    isActive: undefined,
    customerType: CustomerType.Member,
  };

  fetchData(params: never) {
    return api.customer.getAll(params);
  }

  // 跳转详情页
  handleDetail(index: number, row: CustomerDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "memberDetail",
      params: { id: row.id!.toString() },
    });
  }
}
